import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  Alert,
  CardText,
  DropdownItem,
  Tooltip,
} from "reactstrap";
import { MdAlarm, MdAlarmOn, MdAttachMoney, MdHelp, MdMoneyOff } from "react-icons/md"
import { OffcanvasProvider, Trigger, Offcanvas } from 'react-simple-offcanvas'
import { isMobile } from 'react-device-detect';
import { get } from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward, faBullhorn } from '@fortawesome/free-solid-svg-icons'
import StepCard from './stepcard';
import { Capacitor, Plugins } from '@capacitor/core';
import Skeleton from "react-skeleton-loader";
import Cookies from "js-cookie";
import moment from "moment";
import "moment-timezone";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import Tablelist from "../Utility/tablelist";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import campaignImg from "../../assets/images/campaigns3.svg";
import surveyImg from "../../assets/images/surveys2.svg";
import { FaCompass } from "react-icons/fa";

import { TokenAppenditure } from "../Utility/common";
import OnboardingForm from "../Campaigns/OnboardingForm";
import MissingInfoModal from "./MissingInfoModal";
import ConfirmInfoModal from "./ConfirmInfoModal";
import config from "../../config"

const Dashboard = () => {
  const location = useLocation();
  const [registrationToken, setRegistrationToken] = React.useState('');
  const { PushNotifications } = Plugins;

  React.useEffect(() => {
    const listener = (token) => {
      // console.log('Push registration token:', token.value);
      setRegistrationToken(token.value);
      // Send the token to your server for storing
    };
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener('registration', listener);
      return () => PushNotifications.removeListener('registration', listener);
    }
  }, []);

  let params = queryString.parse(location.search);
  const [PayPalEmail, setPayPalEmail] = useState();
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [logInUsername, setLogInUsername] = useState();
  const [missingInfoModal, setMissingInfoModal] = useState(false);
  const toggleMissingInfoModal = () => {
    setMissingInfoModal(!missingInfoModal);
  }
  const [confirmInfoModal, setConfirmInfoModal] = useState(false);
  const toggleConfirmInfoModal = () => {
    setConfirmInfoModal(!confirmInfoModal);
  }

  const [privacyPolicyID, setPrivacyPolicyID] = useState("");
  const [tosID, setTOSID] = useState("");
  const [informationConfirmedDateTime, setInformationConfirmedDateTime] = useState(true);
  const [isManager, setIsManager] = useState(true);
  const [totalEarningsTooltip, setTotalEarningsTooltip] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState();
  const [missedEarningsTooltip, setMissedEarningsTooltip] = useState(false);
  const [totalEarnings, setTotalEarnings] = useState("");
  const [possibleEarnings, setPossibleEarnings] = useState("");
  const [tos, setTOS] = useState("<p></p>");
  const [privacyPolicy, setPrivacyPolicy] = useState("<p></p>");
  const [deadlines, setDeadlines] = useState();
  const [contactID, setContactID] = useState();
  const loginToken = Capacitor.isNativePlatform() ? params.token : Cookies.get("LoginToken"); // => 'value'
  const [contests, setContests] = useState();
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00-0:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    const momentT = moment(t).tz("America/Chicago"); // Ensures CDT
    return momentT.format("M/D/YYYY") === "Invalid date"
      ? ""
      : momentT.format("MMM D hh:mmA z");
  };

  const calcContestDateValue = (d) => {
    const t = new Date(d);
    const momentT = moment(t);

    return momentT.tz(moment.tz.guess()).format("M/D/YYYY") === "Invalid date"
      ? ""
      : momentT.tz(moment.tz.guess()).format("MMM D hh:mmA z");
  };
  const [analytics, setAnalytics] = useState();
  const data2 = [
    {
      title:
        totalEarnings.length > 5 ? totalEarnings.split(".")[0] : totalEarnings,
      subtitle: "Total Earnings",
      color: "light-primary",
      tooltip:
        "Total amount of money made from campaigns. Does not include contest earnings.",
      tooltipID: "totalToolTip",
      link: `/payments${TokenAppenditure()}`,
      icon: <MdAttachMoney size={27} />,
      hideMobile: false,
    },
    {
      title:
        possibleEarnings.length > 5
          ? possibleEarnings.split(".")[0]
          : possibleEarnings,
      subtitle: "Missed Earnings",
      tooltip:
        "Amount of money lost from declining campaign invites or failing to meet the required deadlines. Does not include contest earnings.",
      tooltipID: "missedToolTip",
      color: "light-primary",
      link: `/campaigns?type=expired${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`,
      icon: <MdMoneyOff size={27} />,
      hideMobile: false,
    },
    {
      title:
        analytics &&
        analytics.results.Row_Insights[0].Insight_Group_Data[0].Insight_Value,
      subtitle: "Active Campaigns",
      color: "light-primary",
      link: `/campaigns${TokenAppenditure()}`,
      icon: <MdAlarm size={27} />,
      hideMobile: true,
    },
    {
      title:
        analytics &&
        analytics.results.Row_Insights[0].Insight_Group_Data[1].Insight_Value,
      subtitle: "Completed Campaigns",
      link: `/campaigns?type=completed${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`,
      color: "light-primary",
      icon: <MdAlarmOn size={24} />,
      hideMobile: true,
    },
  ];

  const [contactInfo, setContactInfo] = useState();
  const [profiles, setProfiles] = useState();
  useEffect(() => {
    if (!profiles && !isManager) {
      fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/profiles?x-api-key=${loginToken}`, {
        method: "GET",
      })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          }
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          setProfiles(data);
        });
    }

    if (!PayPalEmail) {
      fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/vendor?x-api-key=${loginToken}`, {
        method: "GET",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          // console.log("payment data",data);
          setPayPalEmail(data.results.data.ContactInformation.EmailAddress_Payment ? data.results.data.ContactInformation.EmailAddress_Payment : "");

        });
    }
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/information`,
      { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
       },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
          const cInfo = data.results.ContactInformation;
          setContactInfo(data);
          if (
            !cInfo.AddressLine1 ||
            !cInfo.City ||
            !cInfo.CountryID ||
            !cInfo.FirstName ||
            !cInfo.LastName ||
            !cInfo.StateID ||
            !cInfo.PostalCode ||
            !cInfo.Phone_Primary
          ) {
            setMissingInfoModal(true);
          }

      });

    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/contests`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        setApprovalStatus(d.results.ApprovalStatusDescription);
        setContests(d.results);
      });
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/insights`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        setAnalytics(d);
      });

    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/home?x-api-key=${loginToken}
    `,
      { method: "GET" }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          // window.location.href = "/login";
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log("data",data);
        setTotalEarnings(data.results.TotalEarnings);
        setInformationConfirmedDateTime(data.contactDetail.informationConfirmedDateTime);
        setConfirmInfoModal(!data.contactDetail.informationConfirmedDateTime || (new Date() - new Date(data.contactDetail.informationConfirmedDateTime)) / (1000 * 3600 * 24 * 30.4375) > 12)
        setPossibleEarnings(data.results.PossibleEarnings);
        setLogInUsername(data.contactDetail.logInUsername);
        setContactID(data.results.ContactId);
        setIsManager(data.contactDetail.contactType === "manager");
        if (
          data.results.LegalApprovalStatus[0].NeedsApproval ||
          data.results.LegalApprovalStatus[1].NeedsApproval
        ) {
          setModal(true);
        }
        setData(data);
        let deadlinesFormattedArr = [];
        data.results.Actions[0].Row_Campaigns.map((e) => {
          const handle = e.HandleDetails.map(a => {
            if (a.FieldName === "Handle") {
              return a.FieldValue;
            }
          });
          const platform = e.HandleDetails.map((a) => {
            if (a.FieldName === "Platform") {
              return a.FieldValue;
            }
          });

          let deadlineDate = '';
          if (e.Deadline.DeadlineDate && e.Type === "Campaign") {
            deadlineDate = calcDateValue(e.Deadline.DeadlineDate);
          } else if (e.Deadline.DeadlineDate && e.Type !== "Campaign") {
            deadlineDate = calcContestDateValue(e.Deadline.DeadlineDate);
          }
          deadlinesFormattedArr.push({
            title: e.Title,
            subtitle: `${e.Deadline.DeadlineText} ${e.Deadline.DeadlineDateTime && !e.Deadline.DeadlineDateTime.includes("Invalid date") ? e.Deadline.DeadlineDateTime : ""}`,
            price: e.Payment,
            handle: handle,
            postID: e.ID,
            UID: e.UID,
            platform: platform,
            type: e.Type,
            image: e.Image ? e.Image : "",
            isNew: e.IsNew,
          });
        });
        setDeadlines(deadlinesFormattedArr);

      });


    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/legal`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({application: "Genni Web App", type: "Terms of Service"})
       }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        setTOSID(d.results.data.LegalID);
        setTOS(d.results.data.Information);
      });
      fetch(
        `${config.genni.marketing_portal.API_KEY}/creatorapi/legal`,
        { method: "POST",
          headers: { "Content-Type": "application/json", "x-api-key": loginToken },
          body: JSON.stringify({application: "Genni Web App", type: "Privacy Policy"})
         }
      )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        // console.log("d data",d.data);
        setPrivacyPolicyID(d.results.data.LegalID);
        setPrivacyPolicy(d.results.data.Information);
      });
  }, [isManager]);

  const acceptLegal = () => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/legal-approval`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({legalId:tosID, contactId: contactID, optout: false})
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        fetch(
          `${config.genni.marketing_portal.API_KEY}/creatorapi/legal-approval`,
          { method: "POST",
            headers: { "Content-Type": "application/json", "x-api-key": loginToken },
            body: JSON.stringify({legalId:privacyPolicyID, contactId: contactID, optout: false})
          }
        )
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
            //const jsonRes = JSON.parse(res);
          })
          .then((e) => {
            setModal(false);
          });
      });
  }

  const CampaignCard = () => {
    return (
      <CardBody style={{ borderRadius: 8 }} className="p-3">
        {/* {data && data.data.ActiveCampaignCount > 0 && (
          <div className="pill bg-pink coming-soon mr-1 hide-mobile">
            {data.data.ActiveCampaignCount} Active
          </div>
        )} */}
        <div
          className="d-flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="campaignTabImgContainer" style={{ marginBottom: 5, width: 35 }}>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style={{ enableBackground: "new 0 0 512 512" }}
              xmlSpace="preserve"
              fill="#162638"
            >
              <g>
                <g>
                  <path
                    d="M33.391,155.826C14.984,155.826,0,170.793,0,189.217v133.565c0,18.424,14.984,33.391,33.391,33.391
          c18.408,0,33.391-14.967,33.391-33.391V189.217C66.783,170.793,51.799,155.826,33.391,155.826z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M478.609,55.652c-18.424,0-33.391,14.967-33.391,33.391v333.913c0,18.424,14.967,33.391,33.391,33.391
          c18.424,0,33.391-14.967,33.391-33.391V89.044C512,70.619,497.033,55.652,478.609,55.652z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M100.174,183.489v145.023l33.391,10.017v29.548c0,22.272,14.348,41.543,35.706,47.934l100.174,30.066
          c14.801,4.53,31.164,1.972,44.282-7.794c12.677-9.417,19.967-23.89,20.126-39.667l77.972,20.052V93.332L100.174,183.489z
          M300.522,398.108c0,10.833-10.301,19.238-21.49,16.011l-100.174-30.066c-7.109-2.153-11.902-8.576-11.902-15.978v-19.531
          l133.565,40.07V398.108z"
                  />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg></div>
          <h6 className="fw-bold" id="actionCardTitle">Campaigns</h6>
        </div>
      </CardBody>
    );
  };

  const [isOpen, setIsOpen] = useState(false);
  function handleOpen() {
    setTimeout(() => {
      setIsOpen(true);
    }, 500);
  }
  function handleClose() {
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  }
  const classNameExists = document.getElementsByClassName('styles-module_show__2crlL').length > 0;
  return (
    <React.Fragment>
      <style>{`${classNameExists && `html{overflow:hidden !important`}}`}</style>
      <div className={"container"} style={{ marginTop: `${Capacitor.isNativePlatform() ? '115px' : '15px'}`, overflow: `${"hidden"}` }}>
        {/* <Alert color="warning">We are currently experiencing some technicaly difficulties. We apologize for the inconvenience. Please check back soon!</Alert> */}
        {/* {contactInfo && (
          <div className="card p-3 smooth">
            <a href={`https://docs.google.com/forms/d/e/1FAIpQLSfymz9pf4AbRSSfd_GeqaPycoteJlCoZEJQevwi5hXUSxlslw/viewform`} target="_blank" >
              <div className="banner-launch">
                <div className="d-flex al-center jc-center fd-row">
                  <div className="media mr-10 hide-mobile">
                    <div
                      className="d-flex al-center jc-center"
                      style={{
                        height: 50,
                        width: 50,
                        background: "rgb(233, 236, 239)",
                        borderRadius: 3
                      }}
                    >
                      <img
                        src={surveyImg}
                        alt="campaignimg"
                        style={{ display: "block", width: 27, height: 27 }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <h5 className="fw-bold mb-0" >Do you want a free pair of tickets to a Dolly Parton 'Rockstar' First Listen Event in cinemas around the world?</h5>
                    <p className="mb-0 text-muted text-sm" style={{ fontSize: 13, lineHeight: 1.3 }}>Press apply and fill out the form to be considered</p>
                  </div>
                </div>
                <div className="btn btn-pink">Apply</div>
              </div>
            </a>
          </div>
          )} */}
        {/* <StepCard /> */}
        <Card className="card-statistics">
          <CardHeader className="bg-white border-bottom">
            <h4 className="madeGentle mb-0">Home</h4>
          </CardHeader>

          <CardBody className="statistics-body">
            <Row>
              {data2.map((e) => {
                return (
                  <Col
                    lg="3"
                    md="6"
                    xs="6"
                    style={{ marginBottom: 20 }}
                    className={`${e.hideMobile && "hide-mobile"}`}
                  >
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        if (!e.tooltip) {
                          window.location.href = e.link;
                        }
                      }}
                    >
                      <div className="my-auto">
                        <div className="iconBadge">{e.icon}</div>
                        <div>
                          <h6
                            className="fw-bolder mb-0 madeGentle"
                            id={e.tooltipID ? e.tooltipID : ""}
                            style={{ cursor: 'pointer', display: 'flex' }}
                          >
                            {e.title || e.title === 0 ? (
                              <>
                                {e.title}{" "}
                                {e.tooltip && (
                                  <>
                                    <MdHelp style={{ marginLeft: 3 }} />
                                    <Tooltip
                                      isOpen={e.tooltipID === "totalToolTip" ? totalEarningsTooltip : missedEarningsTooltip}
                                      target={e.tooltipID}
                                      placement="bottom"
                                      toggle={() => {
                                        if (e.tooltipID === "totalToolTip") {
                                          setTotalEarningsTooltip(
                                            !totalEarningsTooltip
                                          );
                                        } else {
                                          setMissedEarningsTooltip(!missedEarningsTooltip)
                                        }

                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {e.tooltip}
                                    </Tooltip>
                                  </>
                                )}
                              </>
                            ) : (
                              <Skeleton
                                width="100px"
                                height="15px"
                                color="#e6ebf2"
                              />
                            )}
                          </h6>
                          <CardText
                            className="font-small-4 mb-0"
                            style={{ color: "#162638", fontSize: 14, fontWeight: 500 }}
                          >
                            {e.subtitle}
                          </CardText>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
      <div className="" style={{ marginTop: '0px' }}>
        <div className="container">
          {/* {data && data.data.Banners[0].Display && (
            <div className="card">
              <a href="/account/social" style={{margin:'auto'}}>
                <img src="https://genni-assets.s3.amazonaws.com/brandpricingLogos.png" alt="brandpricing" className="brandpricing"  />
              </a>
            </div>
          )} */}
          <Breadcrumbs title="genni" breadcrumbItem="Earn" />
          <div
            className="waysToEarn mb-0"
            style={{
              display: "grid",
            }}
          >
            <Card style={{ borderRadius: 8 }} className="actionCard mb-0">
              <Link to={`/campaigns${TokenAppenditure()}`}>
                <CampaignCard />
              </Link>
            </Card>
            <Card style={{ borderRadius: 8 }} className="actionCard mb-0">
              <Link to={`/contests${TokenAppenditure()}`}>
                <CardBody className="p-3">
                  {contests && (
                    <div className="pill bg-pink coming-soon mr-1 hide-mobile">
                      {contests && contests.Row_Preffy_Competitions.length} Active
                    </div>
                  )}
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon className="homeImg" icon={faAward} style={{ color: '#162638', width: 26.5 }} />
                    <h6 className="fw-bold">Contests</h6>
                  </div>
                </CardBody>
              </Link>
            </Card>

            <Card
              style={{ borderRadius: 8, }}
              className="actionCard  mb-0 cursor-pointer"
            >
              <Link to={`/explore${TokenAppenditure()}`}>
                <CardBody style={{ borderRadius: 8 }} className="p-3">
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="pill bg-pink coming-soon">New!</div>
                    <FaCompass style={{ width: 33, marginBottom: 7, color: '#162638' }} className="homeImg" alt="contest" />
                    <h6 className="fw-bold">Explore</h6>
                  </div>
                </CardBody>
              </Link>
            </Card>

          </div>
          <Row>
            <Col className="col-12" style={{ marginTop: '20px' }}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">Deadlines</h4>
              </div>
              <Tablelist approvalStatus={approvalStatus} isDashboard data={deadlines ? deadlines : null} />
            </Col>
          </Row>
          <Modal isOpen={modal}>
            <ModalHeader className="madeGentle">
              TOS & Privacy Policy
            </ModalHeader>
            <ModalBody style={{ maxHeight: 300, overflowY: "scroll" }}>
              <div dangerouslySetInnerHTML={{ __html: tos }}></div>
              <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="gray"
                onClick={() => {
                  fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/auth/logout`,
                    {
                      method: "post",
                      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
                    })
                    .then((res) => {
                      if (res.ok) {
                        return res.json();
                      }
                      throw res;
                      //const jsonRes = JSON.parse(res);
                    })
                    .then((data) => {
                      Cookies.set("LoginToken", "");
                      window.location.href = "/login";
                    })
                    .catch((error) => {
                      Cookies.set("LoginToken", "");
                      window.location.href = "/login";
                    });
                }}
              >
                Decline
              </Button>{" "}
              <Button
                color="pink"
                onClick={() => {
                  acceptLegal();
                }}
              >
                Accept
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
      {contactInfo && !isManager && missingInfoModal && !modal && !confirmInfoModal && (
        <MissingInfoModal contactInfo={contactInfo} isOpen={missingInfoModal} toggle={toggleMissingInfoModal} />
      )}

      {confirmInfoModal && contactInfo && profiles && logInUsername && !isManager && !modal && (
        <ConfirmInfoModal isOpen={confirmInfoModal} paymentInfo={PayPalEmail} contactInfo={contactInfo} toggle={toggleConfirmInfoModal} profiles={profiles} setProfiles={setProfiles} />
      )}
    </React.Fragment>
  );
};

export default Dashboard;