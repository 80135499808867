import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { LoginToken, TokenAppenditure } from "../Utility/common";
import { Row, Col, Alert, BreadcrumbItem } from "reactstrap";
import { first } from "lodash";
import { TbAlertCircleFilled } from "react-icons/tb";
import config from "../../config";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/build/index.css';

const MissingInfoModal = ({ isOpen, toggle, contactInfo }) => {
  // console.log("contact info",contactInfo);
  const oldFirstName = contactInfo?.results.ContactInformation.FirstName;
  const [firstName, setFirstName] = useState(
    contactInfo?.results.ContactInformation.FirstName
  );
  const oldLastName = contactInfo?.results.ContactInformation.LastName;
  const [lastName, setLastName] = useState(
    contactInfo?.results.ContactInformation.LastName
  );
  const oldPrimaryPhone = contactInfo?.results.ContactInformation.Phone_Primary;
  const [primaryPhone, setPrimaryPhone] = useState(
    contactInfo?.results.ContactInformation.PrimaryPhone
  );
  const oldAddress = contactInfo?.results.ContactInformation.AddressLine1;
  const [address, setAddress] = useState(
    contactInfo?.results.ContactInformation.AddressLine1
  );
  const oldCity = contactInfo?.results.ContactInformation.City;
  const [city, setCity] = useState(contactInfo?.results.ContactInformation.City);
  const oldCountry = contactInfo?.results.ContactInformation.CountryID;
  const [country, setCountry] = useState(
    contactInfo?.results.ContactInformation.CountryID
  );
  const oldState = contactInfo?.results.ContactInformation.StateID;
  const [state, setState] = useState(
    contactInfo?.results.ContactInformation.StateID
  );
  const oldStateOther = contactInfo?.results.ContactInformation.State_Other;
  const [stateOther, setStateOther] = useState(
    contactInfo?.results.ContactInformation.State_Other
  );
  const oldPostalCode = contactInfo?.results.ContactInformation.PostalCode;
  const oldPrimaryEmail =
    contactInfo?.results.ContactInformation.EmailAddress_Primary;
  const [postalCode, setPostalCode] = useState(
    contactInfo?.results.ContactInformation.PostalCode
  );
  const [errorMessage, setErrorMessage] = useState();
  const loginToken = LoginToken();
  // console.log(state);
  const submitContactInfo = () => {
    const reportedState = state ? state : 111;
    const reportedCountry = country ? country : 1;
    // console.log(city, reportedState, oldPrimaryEmail, reportedCountry)
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/informationsave`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": loginToken,
          
        },
        body: JSON.stringify({
          FirstName: firstName,
          LastName: lastName,
          AddressLine1: address,
          City: city,
          StateId: parseInt(reportedState),
          PostalCode: postalCode,
          CountryId: parseInt(reportedCountry),
          OtherState: stateOther,
          PrimaryEmail: oldPrimaryEmail,
          PrimaryPhone: primaryPhone,
          MobilePhone: primaryPhone,
        }),
      }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setErrorMessage("Unsuccessfully updated. Please try again");
          });
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        toggle();
      });
  };

  const changeState = (e) => {
    setState(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="madeGentle">Missing Information</ModalHeader>
      <ModalBody
        style={{
          overflowY: "scroll",
        }}
      >
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <AvForm className="">
          <div className="campaigns">
            {!oldLastName ||
              (!oldFirstName && (
                <Row className="mb-0">
                  <Col lg="6" sm="6" xs="6">
                    <div className="campaign">
                      <div className="campaign-title">
                        <h6 className=" ">First Name</h6>
                      </div>
                      <AvField
                        name="firstName"
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        type="text"
                        className="inputField mb-3"
                        errorMessage="Invalid First Name"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg="6" sm="6" xs="6">
                    <div className="campaign">
                      <div className="campaign-title">
                        <h6 className=" ">Last Name</h6>
                      </div>
                      <AvField
                        name="lastName"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        type="text"
                        className="inputField mb-3"
                        errorMessage="Invalid Last Name"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              ))}
            {!oldPrimaryPhone && (
              <div className="campaign">
                <div className="campaign-title">
                  <h6 className=" ">Mobile Phone</h6>
                </div>
                <PhoneInput
                  defaultCountry="us"
                  className="mb-3"
                  value={primaryPhone}
                  onChange={(phone) => setPrimaryPhone(phone)}
                />
              </div>
            )}

            {(!oldAddress ||
              !oldCity ||
              !oldState ||
              !oldCountry ||
              !oldPostalCode) && (
              <>
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className=" ">Address</h6>
                  </div>
                  <AvField
                    name="address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    type="text"
                    className="inputField mb-3"
                    errorMessage="Invalid Address"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className=" ">City</h6>
                  </div>
                  <AvField
                    name="city"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    type="text"
                    className="inputField mb-3"
                    errorMessage="Invalid City"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className=" ">State/Province</h6>
                  </div>
                  {contactInfo && (
                    <select
                      onChange={changeState}
                      value={state}
                      className="mb-3 custom-select inputField"
                    >
                      {contactInfo?.results.SelectionTables.State.map(
                        (option, i) => (
                          <option key={i} value={option.StateID}>
                            {option.StateName}
                          </option>
                        )
                      )}
                    </select>
                  )}
                </div>
                {(state === "111" || state === 111) && (
                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className="text-black ">State/Province Other</h6>
                    </div>
                    <AvField
                      name="stateOther"
                      value={stateOther}
                      onChange={(e) => {
                        setStateOther(e.target.value);
                      }}
                      type="text"
                      className="inputField mb-3"
                      errorMessage="Invalid State Other"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                )}
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className=" ">Zip/Postal Code</h6>
                  </div>
                  <AvField
                    name="postalCode"
                    value={postalCode}
                    onChange={(e) => {
                      setPostalCode(e.target.value);
                    }}
                    type="text"
                    className="inputField mb-3"
                    errorMessage="Invalid Post Code"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className=" ">Country</h6>
                  </div>
                  {contactInfo && (
                    <select
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      value={country}
                      className="mb-3 inputField custom-select"
                    >
                      {contactInfo?.results.SelectionTables.Country.map(
                        (option, i) => (
                          <option key={i} value={option.CountryID}>
                            {option.CountryName}
                          </option>
                        )
                      )}
                    </select>
                  )}
                </div>
              </>
            )}
          </div>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Button color="pink" onClick={submitContactInfo}>
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MissingInfoModal;
