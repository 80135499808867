import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, BreadcrumbItem, Alert } from "reactstrap";
import { LoginToken, TokenAppenditure } from "../Utility/common";
import {Capacitor} from "@capacitor/core";
import { TbAlertCircleFilled } from "react-icons/tb";
import {BrandRateTooltip, SongRateTooltip} from "../Utility/modals";
import config from "../../config"

const Social = () => {
  const [platform, setPlatform] = useState("Instagram");
  const [wasAdded, setWasAdded] = useState(false);
  const [platformID, setPlatformID] = useState(1);
  const [followers, setFollowers] = useState();
  const [handle, setHandle] = useState("@");
  const [hasBrandRate, setHasBrandRate] = useState(true);
  const [hasSongRate, setHasSongRate] = useState(true);
  const [brandRate, setBrandRate] = useState();
  const [songRate, setSongRate] = useState();
  const history = useHistory();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const loginToken = LoginToken(); // => 'value'

  const handleBrandRateChange = (e) => {
    setBrandRate(e.target.value);
  };

  const handleSongRateChange = (e) => {
    setSongRate(e.target.value);
  };

  const handleHandleChange = (e) => {
    setHandle(e.target.value ? e.target.value : "@");
  };

  const handleFollowersChange = (e) => {
    setFollowers(e.target.value);
  };

  const changePlatform = (e) => {
    setPlatformID(e.target.value);
  };

  const [data, setData] = useState();
  // console.log("Data",data);
  useEffect(() => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/profile`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({ ContactPlatformID: parseInt(params.ContactPlatformID), UID: params.UID })
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log("63", data);
        setData(data);
        setPlatform(data.results.ContactPlatform.PlatformName);
        setPlatformID(data.results.ContactPlatform.PlatformID ? data.results.ContactPlatform.PlatformID : "1");
        setHandle(data.results.ContactPlatform.Handle ? data.results.ContactPlatform.Handle : "@");
        setFollowers(data.results.ContactPlatform.Followers);
        setBrandRate(data.results.ContactPlatform.BrandRate);
        setHasBrandRate(data.results.ContactPlatform.BrandRate !== "$0.00");
        setHasSongRate(data.results.ContactPlatform.SongRate !== "$0.00");
        setSongRate(data.results.ContactPlatform.SongRate);
        setBrandRate(data.results.ContactPlatform.BrandRate);

      });
  }, []);


  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const updateSocial = () => {
    const filteredHandle = handle.charAt(0) === "@" ? handle.substring(1) : handle;
    const formattedSongRate = songRate && songRate.includes("$") ? parseInt(songRate.slice(1)) : parseInt(songRate);
    const formattedBrandRate = brandRate && brandRate.includes("$") ? parseInt(brandRate.slice(1)) : parseInt(brandRate);

      fetch(
        `${config.genni.marketing_portal.API_KEY}/creatorapi/profile-save`,
        { method: "POST",
          headers: { "Content-Type": "application/json", "x-api-key": loginToken },
          body: JSON.stringify({ContactPlatformID: parseInt(params.ContactPlatformID), UID: params.UID,Handle: filteredHandle, PlatformID: platformID, Followers:followers, BrandRate: parseInt(formattedBrandRate), Rate: parseInt(formattedSongRate)})
         }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          if (!data.results.success) {
            setErrorMessage(data.messages[0]);
          } else {
            setSuccessMessage("Successfully Updated!");
          }
        });


  };

  const addSocial = () => {
    const filteredHandle =
      handle.charAt(0) === "@" ? handle.substring(1) : handle;
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/profile-save`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({Handle: filteredHandle, PlatformID: parseInt(platformID), Followers: parseInt(followers), Rate: parseInt(songRate), BrandRate: parseInt(brandRate)})
       }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log("data", data);
        if (!data.results.success) {
          setErrorMessage(data.results.message);
        } else {
          setWasAdded(true);
          setSuccessMessage(data.results.message);
        }
      });
  };
  const formType =params.UID !== "New" ? "Update" : "Add";
  const isValidForm = () => {
    if (followers >= 0 && handle.length > 1) {
      if (params.UID === "New") {
        if (brandRate && songRate ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (hasBrandRate && hasSongRate) {
          return true;
        }
        if (!hasBrandRate && !hasSongRate) {
          if (brandRate !== "$0.00" && brandRate > 0 && songRate !== "$0.00" && songRate > 0) {
            return true;
          } else {
            return false;
          }
        } else if (!hasBrandRate && hasSongRate) {
          if (brandRate !== "$0.00" && brandRate > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          if (songRate !== "$0.00" && songRate > 0) {
            return true;
          } else {
            return false;
          }
        }

      }
    } else {
      return false;
    }
  }
  const validForm = isValidForm();
  // console.log(validForm);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {!Capacitor.isNativePlatform() && (
                <ol className="breadcrumb mt-0 mb-3">
                  <BreadcrumbItem>
                    <a href={`/settings${TokenAppenditure()}`}>Account Settings</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href={`/account/social${TokenAppenditure()}`}>Social Media Profiles</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <a>{formType} Social</a>
                  </BreadcrumbItem>
                </ol>
              )}


              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">
                  {formType} Social Media Profile
                </h4>
              </div>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              {successMessage && <Alert color="success"><i className="mdi mdi-check-circle"></i>{successMessage}</Alert>}

              <AvForm>
                <div className="campaigns bg-white p-3 card">
                  <div className="campaign mb-2">
                    <div className="campaign-title">
                      <h6 className="">Platform</h6>
                    </div>
                    <select
                      className="inputField custom-select"
                      id="Sortbylist-job"
                      value={platformID}
                      onChange={changePlatform}
                    >
                      {data &&
                        data.results.SelectionTables.Platform.map((e, i) => {
                          return (
                            <option key={i} value={e.PlatformID}>
                              {e.PlatformName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="campaign mt-2">
                    <div className="campaign-title">
                      <h6 className="">Handle</h6>
                    </div>
                    <AvField
                      name="handle"
                      type="text"
                      className="inputField"
                      disabled={formType === "Update"}
                      errorMessage="Invalid Handle"
                      value={`${handle && handle[0] !== "@" ? "@" : ""}${handle}`}
                      onChange={handleHandleChange}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                  {params.UID === "New" && (
                    <div className="campaign">
                      <div className="campaign-title">
                        <h6 className=" ">Followers</h6>
                      </div>
                      <AvField
                        name="followers"
                        type="number"
                        className="inputField"
                        errorMessage="Invalid Followers"
                        value={followers}
                        onChange={handleFollowersChange}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  )}
                    <div className="campaign">
                      <div className="campaign-title">
                        <h6 className=" ">Brand Rate (USD) <BrandRateTooltip /></h6>
                      </div>
                      <div>
                        <i className="mdi mdi-currency-usd input-icon" ></i>
                        <AvField
                          name="brandRate"
                          type="number"
                          className="inputField form-control"
                          style={{paddingLeft:20}}
                          errorMessage="Invalid Brand Promo Rate"
                          value={brandRate && brandRate.includes("$") ? parseInt(brandRate.slice(1)) : parseInt(brandRate)}
                          onChange={handleBrandRateChange}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </div>
                    </div>
                    <div className="campaign">
                      <div className="campaign-title">
                        <h6 className=" ">Song Rate (USD) <SongRateTooltip /></h6>
                      </div>
                      <div>
                        <i className="mdi mdi-currency-usd input-icon" ></i>
                        <AvField
                          name="songRate"
                          type="number"
                          className="inputField"
                          errorMessage="Invalid Song Promo Rate"
                          style={{paddingLeft:20}}
                          value={songRate && songRate.includes("$") ? parseInt(songRate.slice(1)) : parseInt(songRate)}
                          onChange={handleSongRateChange}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </div>
                    </div>
                    {!wasAdded && (
                      <div className="campaign">
                        <div
                          className={`btn gradient-pink btn-pink full-width float-right ${!validForm && "disabled"
                            }`}
                          style={{ width: "100%" }}
                          onClick={() => {
                            if (validForm) {
                              if (params.UID !== "New") {
                                updateSocial();
                              } else {
                                addSocial();
                              }
                            }
                          }}
                        >
                          Submit
                        </div>
                      </div>
                    )}

                </div>
              </AvForm>
            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>
  );
};;

export default Social;
