import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  BreadcrumbItem,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { LoginToken, TokenAppenditure } from "../Utility/common";
import {Capacitor} from "@capacitor/core";
import { FaCheckCircle } from "react-icons/fa";

import { TbAlertCircleFilled } from "react-icons/tb";

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/build/index.css';
import config from "../../config";

function range(start, thisYear) {
  const arr = [];
  for (let index = start; index < thisYear; index++) {
    arr.push(index);
  }
  return arr;
}

const Campaigns = () => {
  const [firstName, setFirstName] = useState();
  const [contactId, setContactID] = useState();
  const [lastName, setLastName] = useState();
  const [allowText, setAllowText] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage,setSuccessMessage] = useState();
  const [gender, setGender] = useState("1");
  const [sexualOrientation, setSexualOrientation] = useState("1");
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [birthday, setBirthday] = useState(new Date("1/1/1910"));
  const [state, setState] = useState(111);
  const [postalCode, setPostalCode] = useState();
  const [country, setCountry] = useState(1);
  const [primaryPhone, setPrimaryPhone] = useState();
  const [mobilePhone, setMobilePhone] = useState();
  const [stateOther, setStateOther] = useState();
  const [primaryEmail, setPrimaryEmail] = useState();
  const [otherEmail, setOtherEmail] = useState("");
  const [contactNotes, setContactNotes] = useState();
  const years = range(1910, 2021);
  const loginToken = LoginToken();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const [data, setData] = useState();

  useEffect(() => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/information`,
      { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
       },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
            setData(data);
            setContactID(data.contactDetail.contactID);
            setFirstName(data.results.ContactInformation.FirstName);
            setLastName(data.results.ContactInformation.LastName);
            setAllowText(data.results.ContactInformation.AllowText);
            setBirthday(
              data.results.ContactInformation.Birthday
                ? new Date(data.results.ContactInformation.Birthday)
                : new Date("1/1/1910")
            );
            setStateOther(data.results.ContactInformation.State_Other);
            setSexualOrientation(data.results.ContactInformation.SexualOrientationID ? data.results.ContactInformation.SexualOrientationID : "1");
            setGender(data.results.ContactInformation.GenderIdentityID ? data.results.ContactInformation.GenderIdentityID : "1");
            setAddress(data.results.ContactInformation.AddressLine1);
            setCity(data.results.ContactInformation.City);
            setState(data.results.ContactInformation.StateID ? data.results.ContactInformation.StateID : "111");
            setPostalCode(data.results.ContactInformation.PostalCode);
            setCountry(data.results.ContactInformation.CountryID ? data.results.ContactInformation.CountryID : "1");
            setPrimaryPhone(data.results.ContactInformation.Phone_Primary);
            setMobilePhone(data.results.ContactInformation.Phone_Mobile);
            setPrimaryEmail(data.results.ContactInformation.EmailAddress_Primary);
            setOtherEmail(data.results.ContactInformation.EmailAddress_Other1);
            setContactNotes(
              data.results.ContactInformation.ContactNotes_ByInfluencer
            );
          // if (!data.error){

          // }
      });
  }, []);

  const changeYear = (e) => {
    const newDate = new Date(birthday).setFullYear(e.target.value);
    setBirthday(new Date(newDate));
  };

  const changeMonth = (e) => {
    const newDate = new Date(birthday).setMonth(e.target.value);
    setBirthday(new Date(newDate));
  };

  const changeDay = (e) => {
    const newDate = new Date(birthday).setDate(e.target.value);
    setBirthday(new Date(newDate));
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleContactNotesChange = (e) => {
    setContactNotes(e.target.value);
  };

  const handlePostalCodeChange = (e) => {
    setPostalCode(e.target.value);
  };

  const handlePrimaryPhoneChange = (e) => {
    setPrimaryPhone(e.target.value);
  };

  const handleStateOtherChange = (e) => {
    setStateOther(e.target.value);
  };

  const handleMobilePhoneChange = (e) => {
    setMobilePhone(e.target.value);
  };

  const handleSOChange = (e) => {
    setSexualOrientation(e.target.value);
  };

  const handlePrimaryEmailChange = (e) => {
    setPrimaryEmail(e.target.value);
  };

  const handleotherEmailChange = (e) => {
    setOtherEmail(e.target.value);
  };

  const changeState = (e) => {
    setState(e.target.value);
  };

  const changeCountry = (e) => {
    setCountry(e.target.value);
  };

  const updateContactInfo = (e) => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/informationsave`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({
          FirstName:firstName,
          LastName:lastName,
          AddressLine1:address,
          AddressLine2: "",
          City:city,
          StateId:parseInt(state),
          PersonalPronounID: 1,
          PostalCode:postalCode,
          CountryId:parseInt(country),
          PrimaryPhone:primaryPhone,
          MobilePhone:primaryPhone,
          PrimaryEmail:primaryEmail,
          OtherEmail:otherEmail,
          GenderIdentityID:parseInt(gender),
          SexualOrientationID:parseInt(sexualOrientation),
          InfluencerContactNotes:contactNotes,
          CompanyName:"",
          AllowText:allowText === 1,
          OtherState:stateOther,
          Birthday:`${birthday.getFullYear()}-${
            birthday.getMonth() + 1
          }-${birthday.getDate()}`
        })
      }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setErrorMessage("Unsuccessfully updated. Please try again");
          });
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setErrorMessage();
        setSuccessMessage("Contact Information Successfully Update");
      });
  };

  // console.log("contact data",data);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {!Capacitor.isNativePlatform() && (
                <ol className="breadcrumb mt-0 mb-3">
                  <BreadcrumbItem>
                    <a href={`/settings${TokenAppenditure()}`}>Account Settings</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <a>Contact Information</a>
                  </BreadcrumbItem>
                </ol>
              )}

              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              {successMessage && (
                <Alert color="success"><FaCheckCircle className="mr-2" /><span>{successMessage}</span></Alert>
              )}

              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div>
                  <h4 className="mb-0 madeGentle">Contact Information</h4>
                </div>
              </div>

              <AvForm className="card p-3">
                <div className="campaigns">
                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">Contact ID</h6>
                    </div>
                    <AvField
                      name="contactId"
                      value={contactId}
                      type="text"
                      disabled
                      className="inputField mb-3"
                      errorMessage="Invalid Address"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                  <Row className="mb-0">
                    <Col lg="6" sm="6" xs="6">
                      <div className="campaign">
                        <div className="campaign-title">
                          <h6 className=" ">First Name</h6>
                        </div>
                        <AvField
                          name="firstName"
                          value={firstName}
                          onChange={handleFirstNameChange}
                          type="text"
                          className="inputField mb-3"
                          errorMessage="Invalid First Name"
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="6" sm="6" xs="6">
                    <div className="campaign">
                      <div className="campaign-title">
                        <h6 className=" ">Last Name</h6>
                      </div>
                      <AvField
                        name="lastName"
                        value={lastName}
                        onChange={handleLastNameChange}
                        type="text"
                        className="inputField mb-3"
                        errorMessage="Invalid Last Name"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                    </Col>
                  </Row>
                  <div className="campaign mb-3">
                    <div className="campaign-title">
                      <h6 className="text-black ">Birthday</h6>
                    </div>
                    <div className="birthdayInput">
                      <select
                        onChange={changeMonth}
                        className="inputField"
                        value={new Date(birthday).getMonth()}
                      >
                        {months.map((option, i) => (
                          <option key={option} value={i}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        onChange={changeDay}
                        className="inputField"
                        value={
                          days[parseFloat(new Date(birthday).getDate() - 1)]
                        }
                      >
                        {days.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        onChange={changeYear}
                        className="inputField"
                        value={new Date(birthday).getFullYear()}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <Row>
                  <Col lg="6" sm="6" xs="6">
                    <div className="campaign">
                      <div className="campaign-title">
                        <h6 className=" ">Gender</h6>
                      </div>
                      <select
                        className="inputField custom-select mb-3 inputField"
                        id="Sortbylist-job"
                        value={gender}
                        onChange={handleGenderChange}
                      >
                        {data &&
                          data.results.SelectionTables.GenderIdentity.map((e, i) => {
                            return (
                              <option value={e.GenderIdentityID} key={i}>
                                {e.GenderIdentityDescription}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </Col>
                  <Col lg="6" sm="6" xs="6">
                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">Sexual Orientation</h6>
                    </div>
                    <select
                      className="inputField custom-select mb-3 inputField pr-2"
                      id="Sortbylist-job"
                      value={sexualOrientation}
                      onChange={handleSOChange}
                    >
                      {data &&
                        data.results.SelectionTables.SexualOrientation.map(
                          (e, i) => {
                            return (
                              <option value={e.SexualOrientationID} key={i}>
                                {e.SexualOrientationDescription}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                  </Col>
                  </Row>

                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">Address</h6>
                    </div>
                    <AvField
                      name="address"
                      value={address}
                      onChange={handleAddressChange}
                      type="text"
                      className="inputField mb-3"
                      errorMessage="Invalid Address"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">City</h6>
                    </div>
                    <AvField
                      name="city"
                      value={city}
                      onChange={handleCityChange}
                      type="text"
                      className="inputField mb-3"
                      errorMessage="Invalid City"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">State/Province</h6>
                    </div>
                    {data && (
                      <select
                        onChange={changeState}
                        value={state}
                        className="mb-3 custom-select inputField"
                      >
                        {data.results.SelectionTables.State.map((option, i) => (
                          <option key={i} value={option.StateID}>
                            {option.StateName}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  {(state === "111" || state === 111) && (
                    <div className="campaign">
                      <div className="campaign-title">
                        <h6 className="text-black ">
                          State/Province Other
                        </h6>
                      </div>
                      <AvField
                        name="stateOther"
                        value={stateOther}
                        onChange={handleStateOtherChange}
                        type="text"
                        className="inputField mb-3"
                        errorMessage="Invalid State Other"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  )}
                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">Zip/Postal Code</h6>
                    </div>
                    <AvField
                      name="postalCode"
                      value={postalCode}
                      onChange={handlePostalCodeChange}
                      type="text"
                      className="inputField mb-3"
                      errorMessage="Invalid Post Code"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">Country</h6>
                    </div>
                    {data && (
                      <select
                        onChange={changeCountry}
                        value={country}
                        className="mb-3 inputField custom-select"
                      >
                        {data.results.SelectionTables.Country.map((option, i) => (
                          <option key={i} value={option.CountryID}>
                            {option.CountryName}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">Mobile Phone</h6>
                    </div>
                    <PhoneInput
                      defaultCountry="us"
                      className="mb-3"
                      value={primaryPhone}
                      onChange={(phone) => setPrimaryPhone(phone)}
                    />
                  </div>
                  {/* <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">
                        Secondary Phone (Optional)
                      </h6>
                    </div>
                    <AvField
                      name="mobile"
                      value={mobilePhone}
                      onChange={handleMobilePhoneChange}
                      validate={{
                        tel: true,
                      }}
                      type="text"
                      className="inputField mb-3"
                      errorMessage="Invalid Mobile Phone"
                    />
                  </div> */}

                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={allowText}
                      onClick={() => {
                        setAllowText(!allowText);
                      }}
                      id="customControlInline"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customControlInline"
                    >
                      Check to receive text message notifications
                    </label>
                  </div>

                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" "> Email</h6>
                    </div>
                    <AvField
                      name="primaryEmail"
                      value={primaryEmail}
                      onChange={handlePrimaryEmailChange}
                      type="text"
                      className="inputField mb-3"
                      errorMessage="Invalid Email"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className=" ">
                        Secondary Email (Optional)
                      </h6>
                    </div>
                    <AvField
                      name="otherEmail"
                      value={otherEmail}
                      onChange={handleotherEmailChange}
                      type="text"
                      className="inputField mb-3"
                      errorMessage="Invalid Email"
                    />
                  </div>

                  <div className="campaign">
                    <div className="campaign-title">
                      <h6 className="  mb-0">
                        Additional Information
                      </h6>
                      <p className="text-muted mt-0">
                        List any additional information you feel is
                        important for Genni & Co to know.
                      </p>
                    </div>
                    <AvField
                      name="additionalInformation"
                      value={contactNotes}
                      onChange={handleContactNotesChange}
                      type="textarea"
                      className="inputField mb-3"
                      errorMessage="Invalid Additional Information"
                    />
                  </div>
                  <div className="campaign">
                    <div
                      className="btn btn-pink gradient-pink full-width float-right"
                      style={{ width: "100%" }}
                      onClick={updateContactInfo}
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </AvForm>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};;

export default Campaigns;
